@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}

/* width */
/* ::-webkit-scrollbar {
  height: 0px;
  width: 8px;
} */

/* Track */
/* ::-webkit-scrollbar-track { */
  /* background: #f1f1f1c2; */
  /* background: transparent;
  height: 0px;
  width: 4px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #8888883c;
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #55555550;
} */
